/*!
* www.KNACSS.com v7.0.2 (januar, 4 2017) @author: Alsacreations, Raphael Goetter
* Licence WTFPL http://www.wtfpl.net/
*/

/* ----------------------------- */
/* ==Table Of Content            */
/* ----------------------------- */

/*
1- Reboot (basic reset)
2- Libraries  :
  - Base
  - Print
  - Misc (hyphens)
  - Layout (alignment, modules, positionning)
  - Utilities (width and spacers helpers)
  - Responsive helpers
  - WordPress reset (disabled by default)
  - Grillade (Grid System)
3- Components :
  - Media object
  - Autogrid object
  - Skip Links for accessibility
  - Tables
  - Forms
  - Buttons
  - Checkbox
  - Tabs
  - Tags
  - Badges
  - Alerts
*/

@import "_vendor/reboot"; // Bootstrap reboot (basic reset)

// WARNING : you should comment the following @import (variables)
// and move variables file from knacss folder to your own project folder!
@import "_config/variables";

@import "_config/mixins";

// Core Libraries
@import "_library/base";         // basic styles
@import "_library/print";        // print quick reset
@import "_library/layout";       // alignment, modules, positionning
@import "_library/utilities";    // width and spacers helpers
@import "_library/responsive";   // Responsive Web Design helpers
// @import "_library/wordpress"; // WordPress reset and basic styles

// New Grid System by default (Grid Layout). If you prefer old "Flexbox" Grid System, replace file with "_library/grillade-flex"
// Note that none of these file is prefixed by an underscore, in order to compile them.
@import "_library/grillade-grid"; // grid system with Grid Layout

// Components
@import "components/media";      // media object
@import "components/skip-links"; // skip links
@import "components/tables";     // data tables consistency
@import "components/forms";      // forms consistency and styles
@import "components/buttons";    // buttons styles
@import "components/checkbox";   // checkbox, radio, switch styles
@import "components/tabs";       // tabs styles
@import "components/arrows";     // arrows styles
@import "components/tags";        // tags styles
@import "components/badges";     // badges styles
@import "components/alerts";     // alerts styles


/* --------------------------- */
/* Styles Hôpital de Fourvière */
/* --------------------------- */


/* Général */

.mw1200 { 
	max-width:$extra-large; 
	margin-left:auto;
	margin-right:auto;
}

.hide { display:none; }

b, strong { 
	font-family:"BrandonGrotesque-Bold";
	font-weight:$weight-regular;
}

ul { 
	list-style-type: none; 
	li::before {
		content: "• ";
		color: $red-800;
	}
}

hr { margin:$spacer-medium-plus 0; color:$blue-800; background-color:$blue-800; }

h1, h2, h4,
.h1-like, .h2-like, .h4-like {
	text-transform: uppercase;
}
h4, .h4-like { font-family:"BrandonGrotesque-Regular"; font-weight:$weight-regular; }

.gray { background-color:$gray-300; }
.red { 
	color:$red-800; 
	&::before { content:"► "; font-size:1.2rem; }
}

.upper { text-transform:uppercase; }

.back { 
	display:inline-block;
	padding:$spacer-tiny $spacer-small-plus;
	color:$white;
	background-color:$red-800;
	line-height:1.1;
}

.border-right { border-right: 1px solid $blue-800; padding-top:$spacer-small-plus; padding-bottom:$spacer-small-plus; }
.border-bottom-red { border-bottom: 10px solid $red-800; }

.btn {
	text-transform: uppercase;
	font-family:"BrandonGrotesque-Black";
	font-weight:$weight-regular;
	border:1px solid $blue-800;
	background-color: transparent;
	
	&:hover,
	&:focus,
	&:active {
		background-color: $blue-800;
		color:$white;
	}
}

#global { background-color:$white; }


/* Header */

header {
	
	#h-top {
		font-family:"BrandonGrotesque-Regular"; font-weight:$weight-regular;
		border-bottom:1px solid $blue-800;
		margin-bottom:0;
	}
	
	#h-banniere {
		height:34rem;
		background-size: cover;
		background-image:url(../images/header.jpg);
		background-position:bottom;
		justify-content: space-between;
		
		#hb-titre {
			align-self: flex-end;
			
			h1 { 
				font-size:3.8rem; font-family:"BrandonGrotesque-Regular"; font-weight:$weight-regular; 
				b { font-family:"BrandonGrotesque-Black"; }
			}
		}
	}
	
	#h-sstitre {
		color:$white;
		background-color:$blue-800;
		padding:$spacer-small $spacer-medium;
	}
	
}


/* Contenu */

main {
	
	#m-date {
		#md-visuel { justify-content: center; }
		
		#md-infos {
			h2:first-child { color:$red-800; }
			h2:nth-child(2) { font-family:"BrandonGrotesque-Regular"; font-weight:$weight-regular; }
		}
	}
	
	#m-formulaire {
		
		form {
			label { 
				font-family:"BrandonGrotesque-Regular";
				display:block; font-size:1.7rem; text-transform: uppercase; 
				span { font-family:$font-family-base; text-transform: none; }
			}
			input[type="text"], input[type="email"], input[type="submit"] { width:100%; box-shadow:none; }
			input[type="radio"] { box-shadow:none; }
			
			#mf-right {
				.flex-container { align-items: center; }
			}
		}
	
	}
	
	#m-informations {		
		#mi-hopital {
			.h1-like { line-height:1; font-family:"BrandonGrotesque-Regular"; font-weight:$weight-regular; }
			ul { padding-right:$spacer-large; }
		}
		
		#mi-chiffres {
			p { font-family:"BrandonGrotesque-Regular"; font-size:2.5rem; }
		}
	}
	
	#m-pratique {
		#mp-plan .plan { 
		   -webkit-filter: grayscale(100%);
		   -moz-filter: grayscale(100%);
			-ms-filter: grayscale(100%);
			 -o-filter: grayscale(100%);
				filter: grayscale(100%);
			
			.place-card { display:none; }
		}
	}
	
}


/* Footer */

footer {
	color:$white;
	background-color:$blue-800;
	font-family:"BrandonGrotesque-Regular"; font-weight:$weight-regular;
}
